<template>
  <el-dialog
    class="box-dialog"
    title="原材料计算2"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div class="m-b-10">{{ materialStr }}</div>
    <vxe-grid
      ref="refTable1"
      :size="tableConfig.size"
      :border="tableConfig.border"
      :min-height="tableConfig.minHeight"
      :header-cell-class-name="cellClassName"
      :edit-config="tableConfig.editConfig"
      :row-config="tableConfig.rowConfig"
      :mouse-config="tableConfig.mouseConfig"
      :keyboard-config="tableConfig.keyboardConfig"
      :edit-rules="validRules1"
      :columns="tableColumn1"
      :data="selfData"
    >
      <!-- 原料长度 -->
      <template #edit_material_length="{ row }">
        <el-input v-model="row.material_length" type="number" size="mini" clearable />
      </template>

      <!-- 尾料长度 -->
      <template #edit_tailing_length="{ row }">
        <el-input v-model="row.tailing_length" type="number" size="mini" clearable />
      </template>

      <!-- 今日基价 -->
      <template #edit_today_base_price="{ row }">
        <el-input v-model="row.today_base_price" type="number" size="mini" clearable />
      </template>
    </vxe-grid>

    <div class="m-t-20 m-b-10">计算结果</div>
    <vxe-grid
      ref="refTable2"
      class="m-b-20"
      :size="tableConfig.size"
      :border="tableConfig.border"
      :min-height="tableConfig.minHeight"
      :header-cell-class-name="cellClassName"
      :edit-config="tableConfig.editConfig"
      :row-config="tableConfig.rowConfig"
      :mouse-config="tableConfig.mouseConfig"
      :keyboard-config="tableConfig.keyboardConfig"
      :edit-rules="validRules2"
      :columns="tableColumn2"
      :data="selfData"
    >
      <!-- 支重 -->
      <template #edit_single_weight="{ row }">
        <el-input v-model="row.single_weight" type="number" size="mini" clearable />
      </template>

      <!-- 材料单价 -->
      <template #edit_material_price="{ row }">
        <el-input v-model="row.material_price" type="number" size="mini" clearable />
      </template>

      <!-- 每支可切数量 -->
      <template #edit_parts_count="{ row }">
        <el-input v-model="row.parts_count" type="number" size="mini" clearable />
      </template>

      <!-- 每件材料成本 -->
      <template #edit_cost_price_unit="{ row }">
        <el-input v-model="row.cost_price_unit" type="number" size="mini" clearable />
      </template>

      <!-- 所需材料数量 -->
      <template #edit_material_counts="{ row }">
        <el-input v-model="row.material_counts" type="number" size="mini" clearable />
      </template>

      <!-- 所需材料成本 -->
      <template #edit_material_sales_amount="{ row }">
        <el-input v-model="row.material_sales_amount" type="number" size="mini" clearable />
      </template>
    </vxe-grid>

    <ItemTestRule :isMaterial="true" :data.sync="selfData[0]" :disabled="disabled" />

    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ItemTestRule from './item-test-rule'
import math from './math'
export default {
  components: {
    ItemTestRule
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selfShow: false,
      validRules1: {
        // today_base_price: [{ required: true }]
      },
      validRules2: {
        // material_price: [{ required: true }],
        // material_counts: [{ required: true }]
      },
      tableColumn1: [
        {
          title: '原材料尺寸(mm)', // 原料长度(mm)
          field: 'material_length',
          slots: { edit: 'edit_material_length' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '余料尺寸(mm)', // 尾料长度(mm)
          field: 'tailing_length',
          slots: { edit: 'edit_tailing_length' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '今日基价(元/kg)',
          field: 'today_base_price',
          slots: { edit: 'edit_today_base_price' },
          editRender: { autofocus: '.el-input__inner' }
        }
      ],
      tableColumn2: [
        {
          title: '原材料重量(kg/件)', // 支重(kg)
          field: 'single_weight',
          slots: { edit: 'edit_single_weight' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '原材料(元/件)', // 材料单价(元)
          field: 'material_price',
          slots: { edit: 'edit_material_price' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '原材料可分切(件)', // 每支可切数量(件)
          field: 'parts_count',
          slots: { edit: 'edit_parts_count' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '零件材料成本(元)', // 每件材料成本(元)
          field: 'cost_price_unit',
          slots: { edit: 'edit_cost_price_unit' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '原材料所需(件)', // 所需材料数量
          field: 'material_counts',
          slots: { edit: 'edit_material_counts' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '所需材料成本(元)',
          field: 'material_sales_amount',
          slots: { edit: 'edit_material_sales_amount' },
          editRender: { autofocus: '.el-input__inner' }
        }
      ],
      selfData: []
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        rowConfig: {
          isHover: false,
          isCurrent: false
        },
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    },
    // 原材料字符
    materialStr() {
      let result = []
      if (this.data.craft) result.push(this.data.craft)
      if (this.data.texture) result.push(this.data.texture)
      if (this.data.sales_specification) result.push(this.data.sales_specification)
      return result.join('-')
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = [this.$util.deepCopy(val)]
        }
      },
      deep: true,
      immediate: true
    },
    selfData: {
      handler(val) {
        if (JSON.stringify(this.data) !== JSON.stringify(val)) {
          let row = val[0]
          math.totalRowMaterialData(row) // 计算-汇总行材料
          math.totalRowSaleData(row) // 计算-汇总行销售
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 表格单元格样式设置
    cellClassName({ column }) {
      if (column.slots && column.slots.edit) return 'edit'

      return null
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.popupCancel()
      if (JSON.stringify(this.data) !== JSON.stringify(this.selfData[0])) {
        this.$emit('update:data', this.$util.deepCopy(this.selfData[0]))
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
